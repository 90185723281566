[m-j3oyz3] {
	&.m-j3oyz3 {
		opacity: 0.2 ;
		
		&:hover {
			opacity: 1.0 ;
			transition: opacity 300ms ease-out ;
		}
	}

	&.ui-rule {
		margin: 3rem 0 1.5rem ;
	}
}
