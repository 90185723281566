[m-8404d9] {
	&.state {
		display: flex ;
		flex-wrap: wrap ;
		gap: 1px ;

		a {
			padding: 9px 15px 8px ;
			text-decoration: none ;

			&:first-child {
				border-top-left-radius: 3px ;
			}

			&:last-child {
				border-bottom-right-radius: 3px ;
			}

			&:hover {
				text-decoration: underline ;
			}
		}
	}
}
