[m-500ud5] {
	&.grid {
		margin-bottom: 2.5rem ;
		width: 100% ;

		th,
		td {
			border-bottom: 1px solid #cccccc ;
			padding: 7px 10px ;
		}
		thead th {
			border-bottom-width: 2px ;
		}
		tbody tr:hover th,
		tbody tr:hover td {
			background-color: #f0f0f0 ;
		}
		.env-left {
			border-left: 1px solid #cccccc ;
		}
		.env-right {
			border-right: 1px solid #cccccc ;
		}
		.disabled {
			color: #cccccc ;
		}
	}
}
