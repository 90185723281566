[m-n1883l] {
	&.panels {
		align-items: flex-start ;
		display: flex ;

		&__main {
			flex: 1 1 auto ;
		}

		&__aside {
			box-shadow: -1px 0 #d0d0d0 ;
			display: flex ;
			flex: 0 0 auto ;
			min-height: 100vh ;
			padding: 15px 20px 20px 20px ;
			position: sticky ;
			top: 0 ;
		}
	}

	&.rule {
		border: 1px dashed #999999 ;
		border-radius: 3px ;
		padding: 20px ;

		&:hover {
			border-style: solid ;
		}

		&--disabled {
			opacity: 0.3 ;
		}
	}

	&.editable {
		cursor: pointer ;
		display: flex ;

		&:hover {
			background-color: #f0f0f0 ;
		}

		&__link {
			font-weight: 400 ;
			opacity: 0.7 ;
		}

		&__link:nth-of-type(1) {
			margin-left: auto ;
		}

		&__link:nth-of-type(2) {
			margin-left: 15px ;
		}

		&__link:hover {
			opacity: 1.0 ;
		}
	}

	@keyframes m-n1883l-proximal-animated {
		50% {
			outline-offset: 6px ;
		}
	}

	&.state {
		display: grid ;
		column-gap: 1px ;
		grid-template-columns: 1fr 1fr ;
		min-width: 400px ;

		&__header {
			font-weight: bold ;
			padding: 0 15px 10px ;
			text-align: center ;
		}

		&__variant {
			border-bottom: 0.5px solid #ffffff ;
			min-height: 4px ;
		}

		&__variant:hover {
			outline: 3px solid #ffffff ;
			outline-offset: 0px ;
			z-index: 2 ;
		}
	}
}
