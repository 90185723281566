[m-pt0qmv] {
	&.grid {
		border-collapse: collapse ;
		border-spacing: 0 ;
		width: 100% ;

		th,
		td {
			border-bottom: 1px solid #cccccc ;
			padding: 7px 10px ;
			text-align: left ;
			white-space: nowrap ;
		}

		th {
			border-bottom-width: 2px ;
		}

		th.sticky {
			background-color: #ffffff ;
			padding: 10px ;
			position: sticky ;
			top: -1px ;
			z-index: 2 ;
		}

		.col-group {
			border: 1px dashed #cccccc ;
			border-bottom: 2px solid #cccccc ;
			padding: 10px ;
			text-align: center ;
		}

		.row-group {
			padding: 20px 20px ;
			text-align: center ;
		}

		tbody tr:hover th,
		tbody tr:hover td {
			background-color: #f0f0f0 ;
		}

		.sorter {
			cursor: pointer ;
			user-select: none ;
		}

		.diminish {
			opacity: 0.3 ;
		}

		.highlight {
			background-color: #fff59a ;
		}
	}
}
