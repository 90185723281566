[m-xemm6a] {
	&.panels {
		display: flex ;
		min-height: 100vh ;

		&__main {
			flex: 1 1 auto ;
		}

		&__aside {
			box-shadow: -1px 0 #d0d0d0 ;
			flex: 0 0 auto ;
		}
	}

	&.aside-wrapper {
		position: sticky ;
		top: 0 ;
	}
}
