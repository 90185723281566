[z6oi4e].error-message {
  background-color: #ffe8eb;
  border: 1px solid red;
  border-left-width: 5px;
  padding: .8rem 1rem;
  position: relative;
}

[z6oi4e].scroll-margin {
  pointer-events: none;
  position: absolute;
  top: -30px;
  left: 0;
}

[m-19lq69].rule {
  width: 95%;
  margin-inline: auto;
}

[m-19lq69].wrapper {
  border: 4px solid red;
  width: 95%;
  margin: 20px auto;
  padding: 20px;
}

[m-19lq69].wrapper th, [m-19lq69].wrapper td {
  font-size: 18px;
}

[m-19lq69].wrapper th:not([class]), [m-19lq69].wrapper td:not([class]) {
  word-break: break-word;
}

[m-1owobl].sprite-sheet {
  display: none;
  position: fixed;
}

[m-mlxsai].main, [m-6k72nh].main {
  max-width: 800px;
  margin: 0 auto;
}

[m-2wod20].cf-turnstile {
  margin-top: 30px;
}

[m-2f8ql7].body {
  margin: 0;
}

[m-2f8ql7].skip-to-main {
  color: #f0f0f0;
  z-index: 100;
  background-color: #121212;
  border-radius: 3px;
  padding: 1rem 2rem;
  position: fixed;
  top: -100px;
  left: 6px;
}

[m-2f8ql7].skip-to-main:focus {
  top: 6px;
}

[m-2f8ql7].shell {
  align-items: flex-start;
  display: flex;
}

[m-2f8ql7].shell__header {
  background-color: #fff;
  flex-direction: column;
  flex: none;
  min-height: 100vh;
  display: flex;
  position: sticky;
  top: 0;
  box-shadow: 1px 0 #d0d0d0;
}

[m-2f8ql7].shell__main {
  flex: auto;
}

[m-2f8ql7].shell__main:focus-visible {
  outline-offset: -6px;
  outline-width: 3px;
}

[m-2f8ql7].nav {
  width: 270px;
  padding: 5px 0;
}

[m-2f8ql7].nav__item {
  color: #212121;
  white-space: nowrap;
  border: 4px solid #fff;
  border-width: 4px 8px;
  border-radius: 3px;
  align-items: center;
  gap: 13px;
  padding: 7px 8px;
  text-decoration: none;
  display: flex;
}

[m-2f8ql7].nav__item.is-logo {
  margin-bottom: 20px;
}

[m-2f8ql7].nav__item.is-on {
  background-color: #f2f2f3;
  font-weight: bold;
}

[m-2f8ql7].nav__icon {
  width: 32px;
  height: 32px;
  display: flex;
}

[m-2f8ql7].nav__icon svg {
  width: 24px;
  height: 24px;
  margin: auto;
}

[m-2f8ql7].nav__label u {
  text-decoration: none;
}

[m-2f8ql7].nav__item:hover [m-2f8ql7].nav__label u {
  text-decoration: underline;
}

[m-2f8ql7].video-link {
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-direction: column;
  align-items: center;
  width: 224px;
  margin: auto auto 22px;
  padding: 28px 0 14px;
  text-decoration: none;
  display: flex;
}

[m-2f8ql7].video-link__button {
  color: #333;
  border: 1px solid #333;
  border-radius: 4px;
  padding: 17px 27px 17px 31px;
}

[m-2f8ql7].video-link__button svg {
  width: 24px;
  height: 24px;
}

[m-2f8ql7].video-link__label {
  margin-top: 20px;
}

[m-2f8ql7].video-link:hover {
  border-color: #333;
}

[m-2f8ql7].video-link:hover [m-2f8ql7].video-link__label {
  text-decoration: underline;
}

[m-500ud5].grid {
  width: 100%;
  margin-bottom: 2.5rem;
}

[m-500ud5].grid th, [m-500ud5].grid td {
  border-bottom: 1px solid #ccc;
  padding: 7px 10px;
}

[m-500ud5].grid thead th {
  border-bottom-width: 2px;
}

[m-500ud5].grid tbody tr:hover th, [m-500ud5].grid tbody tr:hover td {
  background-color: #f0f0f0;
}

[m-500ud5].grid .env-left {
  border-left: 1px solid #ccc;
}

[m-500ud5].grid .env-right {
  border-right: 1px solid #ccc;
}

[m-500ud5].grid .disabled {
  color: #ccc;
}

[m-rp1ra0].tile-form {
  margin-bottom: 1rem;
}

[m-rp1ra0].tiles {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

[m-rp1ra0].tile {
  background-color: #fde6ff;
  border: 1px solid #f55dff;
  border-radius: 3px;
  display: flex;
}

[m-rp1ra0].tile__value {
  align-self: center;
  padding: .6rem 1rem .5rem;
}

[m-rp1ra0].tile__remove {
  cursor: pointer;
  background-color: #f55dff;
  border: none;
  margin: 0;
  padding: .6rem 1rem .5rem;
}

[m-n1883l].panels {
  align-items: flex-start;
  display: flex;
}

[m-n1883l].panels__main {
  flex: auto;
}

[m-n1883l].panels__aside {
  flex: none;
  min-height: 100vh;
  padding: 15px 20px 20px;
  display: flex;
  position: sticky;
  top: 0;
  box-shadow: -1px 0 #d0d0d0;
}

[m-n1883l].rule {
  border: 1px dashed #999;
  border-radius: 3px;
  padding: 20px;
}

[m-n1883l].rule:hover {
  border-style: solid;
}

[m-n1883l].rule--disabled {
  opacity: .3;
}

[m-n1883l].editable {
  cursor: pointer;
  display: flex;
}

[m-n1883l].editable:hover {
  background-color: #f0f0f0;
}

[m-n1883l].editable__link {
  opacity: .7;
  font-weight: 400;
}

[m-n1883l].editable__link:first-of-type {
  margin-left: auto;
}

[m-n1883l].editable__link:nth-of-type(2) {
  margin-left: 15px;
}

[m-n1883l].editable__link:hover {
  opacity: 1;
}

@keyframes m-n1883l-proximal-animated {
  50% {
    outline-offset: 6px;
  }
}

[m-n1883l].state {
  grid-template-columns: 1fr 1fr;
  column-gap: 1px;
  min-width: 400px;
  display: grid;
}

[m-n1883l].state__header {
  text-align: center;
  padding: 0 15px 10px;
  font-weight: bold;
}

[m-n1883l].state__variant {
  border-bottom: .5px solid #fff;
  min-height: 4px;
}

[m-n1883l].state__variant:hover {
  outline-offset: 0px;
  z-index: 2;
  outline: 3px solid #fff;
}

[m-8404d9].state {
  flex-wrap: wrap;
  gap: 1px;
  display: flex;
}

[m-8404d9].state a {
  padding: 9px 15px 8px;
  text-decoration: none;
}

[m-8404d9].state a:first-child {
  border-top-left-radius: 3px;
}

[m-8404d9].state a:last-child {
  border-bottom-right-radius: 3px;
}

[m-8404d9].state a:hover {
  text-decoration: underline;
}

[m-bac8og].grid {
  width: 100%;
}

[m-bac8og].grid th, [m-bac8og].grid td {
  border-bottom: 1px solid #ccc;
  padding: 7px 10px;
}

[m-bac8og].grid thead th {
  border-bottom-width: 2px;
}

[m-bac8og].grid tbody tr:hover th, [m-bac8og].grid tbody tr:hover td:not(.variant) {
  background-color: #f0f0f0;
}

[m-bac8og].grid .env-left {
  border-left: 1px solid #ccc;
}

[m-bac8og].grid .env-right {
  text-align: center;
  border-right: 1px solid #ccc;
}

[m-bac8og].grid .disabled {
  color: #ccc;
}

[m-pt0qmv].grid {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

[m-pt0qmv].grid th, [m-pt0qmv].grid td {
  text-align: left;
  white-space: nowrap;
  border-bottom: 1px solid #ccc;
  padding: 7px 10px;
}

[m-pt0qmv].grid th {
  border-bottom-width: 2px;
}

[m-pt0qmv].grid th.sticky {
  z-index: 2;
  background-color: #fff;
  padding: 10px;
  position: sticky;
  top: -1px;
}

[m-pt0qmv].grid .col-group {
  text-align: center;
  border: 1px dashed #ccc;
  border-bottom: 2px solid #ccc;
  padding: 10px;
}

[m-pt0qmv].grid .row-group {
  text-align: center;
  padding: 20px;
}

[m-pt0qmv].grid tbody tr:hover th, [m-pt0qmv].grid tbody tr:hover td {
  background-color: #f0f0f0;
}

[m-pt0qmv].grid .sorter {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

[m-pt0qmv].grid .diminish {
  opacity: .3;
}

[m-pt0qmv].grid .highlight {
  background-color: #fff59a;
}

[g7tzc2].video {
  margin: 3rem 0;
}

[g7tzc2].video:target {
  border: 2px solid var(--accent-color);
  padding: 1.2rem 1.8rem 1.5rem;
}

[g7tzc2].video-frame {
  aspect-ratio: 560 / 315;
  width: 100%;
  max-width: 800px;
  height: auto;
  display: block;
  box-shadow: 0 0 0 1px #ccc;
}

[g7tzc2].video-placeholder {
  aspect-ratio: 560 / 315;
  background-color: #fafafa;
  width: 100%;
  max-width: 800px;
  height: auto;
  display: block;
  box-shadow: 0 0 0 1px #ccc;
}

[g7tzc2].return {
  margin-top: 1.5rem;
}

[m-xemm6a].panels {
  min-height: 100vh;
  display: flex;
}

[m-xemm6a].panels__main {
  flex: auto;
}

[m-xemm6a].panels__aside {
  flex: none;
  box-shadow: -1px 0 #d0d0d0;
}

[m-xemm6a].aside-wrapper {
  position: sticky;
  top: 0;
}

[m-j3oyz3].m-j3oyz3 {
  opacity: .2;
}

[m-j3oyz3].m-j3oyz3:hover {
  opacity: 1;
  transition: opacity .3s ease-out;
}

[m-j3oyz3].ui-rule {
  margin: 3rem 0 1.5rem;
}

[m-23jyxr].grid thead th {
  text-align: left;
  padding: 0 5px 5px;
}

[m-23jyxr].grid thead th:first-child {
  padding-left: 0;
}

[m-23jyxr].grid tbody th {
  text-align: left;
  padding: 5px 20px 5px 0;
}

[m-23jyxr].grid td {
  padding: 5px 20px 5px 0;
}

[m-23jyxr].grid td:last-child {
  padding-right: 0;
}

[m-23jyxr].legend {
  gap: 13px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

[m-23jyxr].legend li {
  margin: 0;
  padding: 0;
}

[m-23jyxr].legend span {
  border-radius: 3px;
  padding: 3px 10px;
  display: block;
}

[m-23jyxr].states {
  gap: 3px;
  display: flex;
}

[m-23jyxr].states span {
  border-radius: 3px;
  flex: none;
  width: clamp(13px, 1vw, 23px);
  height: clamp(13px, 1vw, 23px);
}

@keyframes m-23jyxr-pulsate {
  50% {
    border-radius: 10px;
    transform: scale(.1);
  }
}

[m-23jyxr].journey .current {
  background-color: #f0f0f0;
  font-weight: bold;
}
/*# sourceMappingURL=main.82ee3927.css.map */
