
[m-19lq69] {
	&.rule {
		margin-inline: auto ;
		width: 95% ;
	}

	&.wrapper {
		border: 4px solid red ;
		margin: 20px auto 20px auto ;
		padding: 20px 20px 20px 20px ;
		width: 95% ;

		th,
		td {
			font-size: 18px ;

			// The text-only TDs have no class attribute, and should wrap to avoid overflow.
			&:not([class]) {
				word-break: break-word ;
			}
		}
	}
}
