
[z6oi4e] {
	&.error-message {
		background-color: #ffe8eb ;
		border: 1px solid #ff0000 ;
		border-left-width: 5px;
		padding: 0.8rem 1rem ;
		position: relative ;
	}

	&.scroll-margin {
		pointer-events: none ;
		position: absolute ;
		left: 0 ;
		top: -30px ;
	}
}
