[m-rp1ra0] {
	&.tile-form {
		margin-bottom: 1rem ;
	}

	&.tiles {
		align-items: flex-start ;
		display: flex ;
		flex-direction: column ;
		gap: 10px ;
	}

	&.tile {
		background-color: #fde6ff ;
		border: 1px solid #f55dff ;
		border-radius: 3px ;
		display: flex ;

		&__value {
			align-self: center ;
			padding: 0.6rem 1rem 0.5rem ;
		}
		&__remove {
			background-color: #f55dff ;
			border: none ;
			cursor: pointer ;
			margin: 0 ;
			padding: 0.6rem 1rem 0.5rem ;
		}
	}
}
