[g7tzc2] {
	&.video {
		margin: 3rem 0 ;

		&:target {
			border: 2px solid var( --accent-color ) ;
			padding: 1.2rem 1.8rem 1.5rem 1.8rem ;
		}
	}

	&.video-frame {
		aspect-ratio: 560 / 315 ;
		box-shadow: 0px 0px 0px 1px #cccccc ;
		display: block ;
		height: auto ;
		max-width: 800px ;
		width: 100% ;
	}

	&.video-placeholder {
		aspect-ratio: 560 / 315 ;
		background-color: #fafafa ;
		box-shadow: 0px 0px 0px 1px #cccccc ;
		display: block ;
		height: auto ;
		max-width: 800px ;
		width: 100% ;
	}

	&.return {
		margin-top: 1.5rem ;
	}
}
